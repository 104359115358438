.FieldControl {
    width: 8.77vw;
}

.FieldControl2 {
    width: 100%;
}

.FieldControl input,
.FieldControl2 input,
.FieldControl3 input {
    margin-top: 0;
}

.FieldControl label,
.FieldControl2 label,
.FieldControl3 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.InputContainer2 {
    display: flex;
    grid-column-gap: 1vw;
    align-items: start;
}

.FormContainer {
    margin-top: 1vw;
}

.Subtitle {
    font-size: 0.72vw;
    font-weight: 500;
    margin: 1.5vw 0 0 0;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 1vw;
    margin-top: 1vw;
}

.ButtonContainer button {
    width: 5vw;
}

.FormContainer2 {
    margin-top: 1vw;
    max-height: 65vh;
    overflow-y: scroll;
}

.FlexBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.FieldControl3 {
    width: 15vw;
}

.systemFormModal {
    margin-top: 1vw;
    min-width: 34vw;
}

.FormWrapper {
    max-height: 60vh;
    overflow-y: scroll;
}
