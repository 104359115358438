.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}

.FieldInput label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    align-items: flex-end;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.AmountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BoldFont {
    font-weight: bold;
}

.GreenColor {
    color: green;
}

.RedColor {
    color: red;
}
