.FilterContainer {
    background: white;
    padding: 1.04vw;
    margin-bottom: 1vw;
    border-radius: 0.78vw;
    display: flex;
    justify-content: space-between;
}

.FlexCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FilterWarapper {
    border-radius: 0.7vw;
    margin-top: 0;
    padding: 0.5vw 1vw 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CardContainer {
    display: flex;
    margin-top: 2vh;
    gap: 1vw;
    padding-left: 1vw;
    padding-top: 1vw;
}

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.Card {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
}

.ChartContainer {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
}

.Events {
    background: white;
    padding: 0.5vw 1vw 1vw;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.FieldControl {
    width: 8.77vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.SubmitButton {
    width: 7.4vw;
    align-self: flex-end;
    margin-bottom: 0.2vw;
}

.Icon {
    padding: 0 1vw;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 0.2vw;
}

.MenuOptions {
    font-size: 14px;
}

.Menu {
    border-radius: 0.75rem;
}
