.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.ModalWrapper {
    overflow: auto;
}

.label {
    font-size: 12px;
}

.chipsContainer {
    display: flex;
    gap: 0.8vw;
    padding-left: 1vw;
    margin-top: 1vw;
    flex-wrap: wrap;
}

.chips {
    cursor: pointer;
    border-radius: 12px;
    border: solid 1px;
    padding: 3px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    background-color: var(--color-primary);
    color: white;
    font-size: 0.8vw;
}

.FieldControl {
    width: 14vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    gap: 1vw;
}

.FormContentNoSpace {
    margin-bottom: 0.4rem;
}

.PowerTabHeader {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    padding-left: 1vw;
}

.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}

.FieldInput label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.TabsContainer {
    padding-top: 1vw;
}

.Events {
    background: white;
    padding: 0.5vw 1vw 1vw;
}

.Duration {
    width: 75.8vw;
    height: 2.34vw;
    background: #f4f7fc;
    margin: 1.56vw 0 0 0;
    display: flex;
    align-items: center;
}

.DurationContent {
    text-align: center;
    font-weight: 600;
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0.625vw 1.82vw;
}

.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.TableFirstChild th:first-child {
    padding-left: 0;
}

.TabelHeading {
    padding-top: 1vw;
    padding-left: 0.5vw;
}

.SubmitButton {
    width: 7.4vw;
    align-self: center;
}

.ButtonContainer2 {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer {
    width: 10.39vw;
    margin-top: 3vw;
    margin-left: auto;
}

.FieldControl2 {
    width: 7vw;
}

.FieldControl2 input {
    margin-top: 0;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.MenuItem {
    padding-left: 1.55vw;
}

.MenuOptions {
    font-size: 14px;
}

.MenuOptions a {
    color: black;
    text-decoration: none;
}

.Menu {
    border-radius: 0.75rem;
}

.LogContainer {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}

.ButtonRight {
    width: 10.39vw;
    margin-top: 1vw;
    margin-left: auto;
}

.ModalContainer {
    width: 70vw;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.AmountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BoldFont {
    font-weight: bold;
}

.GreenColor {
    color: green;
}

.RedColor {
    color: red;
}

.TableHeadingPaddingBottom {
    padding-bottom: 0.5vw;
}

.ColorInfo {
    display: flex;
    font-size: 0.8vw;
    letter-spacing: 0px;
    color: #818e94;
    gap: 0.2vw;
}
.ConsumptionInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2vw;
}
