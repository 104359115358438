.SelectedFile {
    width: 98%;
    height: 3.542vw;
    border: 0.052vw solid #dee4eb;
    border-radius: 0.417vw;
    background: #ffffff;
    margin-top: 0.26vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SelectedFile p {
    color: var(--gray-700) !important;
    font-weight: 600 !important;
    font-size: 0.833vw !important;
    position: relative;
    left: 0.885vw;
}

.CloseIcon {
    position: relative;
    right: 1vw;
    top: 0.156vw;
    cursor: 'pointer';
}

.UploadSection {
    display: flex;
    gap: 5vw;
    overflow: hidden;
}

.UploadedFiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.HeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.Container {
    width: 98vw;
    height: 98vh;
    overflow: auto;
}

.Uploading {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.HeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1vw;
}

.AccordionPanel {
    padding: 0.5vw 0.3vw 0 2vw !important;
}

.AccordionHeading {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.AccordionItem {
    margin-bottom: 1vh;
}

.TextContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
}

.SwitchView {
    display: flex;
    align-items: center;
    gap: 0.7vw;
}

.SwitchView > label {
    font-size: 0.9vw;
}

.PageContainer {
    display: flex;
    gap: 0.5vw;
    height: 4vh;
    border: 1px solid #ccced1;
    border-top: none;
    align-items: center;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: var(--gray-100);
    align-items: center;
}

.PageInput:focus {
    outline: none;
}

.PageInput {
    border: none;
    background-color: transparent;
    height: 2.7vh;
    padding-left: 0.5vw;
    padding-right: 0.3vw;
}

.ActivePage {
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid var(--color-primary);
    border-left: 1px solid #ccced1;
    border-right: 1px solid #ccced1;
    border-top: 1px solid white;
    height: 3.7vh;
    margin-top: -1px;
}

.Breadcrumb {
    display: flex;
    align-items: center;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.35);
    overflow: hidden;
    border-radius: 5px;
}

.Breadcrumb input:focus {
    outline: none;
}

.Breadcrumb input {
    border: none;
    font-size: 0.8vw;
    line-height: 4.5vh;
    background: white;
}
