.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.DataMonitoringContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ToggleContainer {
    display: flex;
    gap: 2vw;
}

.Menu {
    border-radius: 0.75rem;
}

.ActionContainer {
    display: flex;
    gap: 0.5vw;
}

.SnoozeHeader {
    font-size: 17px;
    font-weight: 600;
}

.LicenseMetaKeysContainer {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1vw;
    row-gap: 0.5vw;
}

.IconContainer {
    display: flex;
    gap: 0.5vw;
    margin-top: 1vh;
    justify-content: flex-end;
}
