.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1.3vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}

.FieldInput label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.Card {
    font-weight: 500;
    font-size: medium;
    margin: 0;
    width: 9.5vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
    min-height: 8.2vh;
    position: relative;
    flex-direction: column;
    justify-content: center;
}

.Card p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.Card div {
    font-weight: 700;
    font-size: 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
}

.CardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ChartCardContainer {
    display: flex;
    gap: 1vw;
}

.ChartCard {
    flex: 1;
    font-weight: 500;
    font-size: medium;
    margin: 0;
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
    height: 49vh;
}

.NotificationCard {
    font-weight: 500;
    font-size: medium;
    margin: 0;
    padding: 0.5vw;
    width: 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.58vw;
    text-align: center;
    height: 49vh;
}

.ChartCard,
.NotificationCard p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.SnoozeContainer {
    display: flex;
    justify-content: space-between;
    font-size: 0.72vw;
    margin-top: 1vw;
}

.SecondRowCard {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.58vw;
    padding: 0.5vw;
    height: 49vh;
    position: relative;
}

.Info-icon {
    position: absolute;
    top: 0.6vw;
    color: #65b741;
    padding-left: 0.2vw;
    cursor: pointer;
}

.Label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}
