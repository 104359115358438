.Swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
}

.Color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.Popover {
    position: absolute;
    z-index: 2;
    bottom: 1.9rem;
}

.Cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.Wrapper {
    margin-top: 1vw;
    font-size: 0.72vw;
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
    font-weight: 500;
    position: relative;
}
