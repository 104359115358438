.TableContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.Menu {
    border-radius: 0.75rem;
}

.SnoozeHeader {
    font-size: 17px;
    font-weight: 600;
}

.ActionContainer {
    display: flex;
    justify-content: center;
}

.ButtonContainer {
    position: absolute;
    bottom: 1vw;
    right: 1vw;
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.5vw;
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}

.ActionContainer img {
    width: 1.2vw;
    height: 1vw;
}

.Action {
    display: flex;
    grid-column-gap: 0.5vw;
}
