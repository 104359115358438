.TimeContainer {
    width: auto;
    height: 2vw;
    border: 1px solid #dee4eb;
    border-radius: 0.417vw;
    background: #f4f7fa;
}

.Time {
    height: 100%;
    width: 100%;
}

.TimePicker {
    width: auto;
    height: 2.5vw;
    border: 1px solid #dee4eb;
    border-radius: 0.6vw;
    background: #f4f7fa;
}
