.Card {
    width: 30%;
    height: 10.6vw;
    border-radius: 0.78vw;
    background: white;
    padding: 1.56vw 0.83vw;
    cursor: pointer;
}

.Card2 {
    width: 30%;
    height: 10.6vw;
    border-radius: 0.78vw;
    background: white;
    padding: 1.56vw 0.83vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.Card2 img {
    width: 5vw;
    height: 5vw;
}
.TitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0vw;
}
.TitleWrapper img {
    cursor: pointer;
}
.Title {
    font-size: 0.83vw;
    letter-spacing: 0px;
    color: var(--color-primary);
    text-transform: capitalize;
    opacity: 1;
    font-weight: bold;
}

.SubTitle {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 500;
}

.Content ul {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    line-height: 2;
    padding-inline-start: 0;
    margin-left: 1.6vw;
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 2vw;
    list-style-position: inside;
}

.CardsContainer {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    justify-content: flex-start;
    align-items: center;
}
.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input,
.FieldControl2 input {
    margin-top: 0 !important;
}

.FieldControl label,
.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FieldControl2 {
    width: 100%;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
    align-items: center;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ActionWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-column-gap: 0.5vw;
}

.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.SubTitleWrapper {
    display: flex;
    gap: 1vw;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.VersionDetailsWrapper {
    min-width: 20vw;
}
