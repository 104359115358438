.Label {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ImageContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
}

.ImageIcon {
    height: 1.5vw;
}

.IconWrapper {
    display: flex;
    justify-content: end;
    margin-top: 1vh;
    gap: 0.3vw;
}

.NameWrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    background: white;
    border-radius: 8px;
    margin: 0.5vw;
    align-items: center;
    opacity: 0;
    transition: 1s;
}

.MainDiv:hover div {
    opacity: 1;
}

.MainDiv:hover {
    box-shadow: 0 5px 20px grey;
    transform: scale(1.1);
}

.MainDiv {
    height: 200px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0.5vw;
    background-position: center;
    box-shadow: 0 5px 15px grey;
    transition: transform 0.5s ease;
}
