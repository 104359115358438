.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
