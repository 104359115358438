.ActionIcon {
    height: 1.2vw;
}

.FlexContainer {
    display: flex;
    flex-direction: column;
    margin-top: 1vw;
}

.FirstBlock {
    padding: 0 1vw 1vw;
}

.SecondBlock {
    padding: 0 1vw 1vw;
}

.BlockHead {
    display: flex;
    justify-content: center;
    text-decoration: underline;
}

.ReplyWrapper {
    padding-top: 1vw;
    flex-grow: 1;
}

.ButtonFlexRow {
    display: flex;
    justify-content: space-between;
}

.ReplyArea {
    background-color: var(--color-primary);
    color: white;
    border-radius: 0.5vw;
    border: none;
    resize: none;
    min-height: 55vh;
    width: 100% !important;
    height: 100% !important;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 1vw;
    margin-top: 1vw;
}

.SendIcon {
    height: 1vw;
    width: 1vw;
    color: white !important;
    filter: invert(90%) sepia(38%) saturate(17%) hue-rotate(308deg) brightness(105%) contrast(104%);
}

.SendCustomBtn {
    width: 5vw !important;
    height: 2.3vw;
    min-width: auto !important;
}

.CustomWrapper {
    display: flex;
    align-items: center;
    margin-top: 1vw;
    justify-content: space-between;
}

.InputContainer {
    width: 62vw;
}

.GraphContainer {
    display: flex;
}

.GraphWrapper {
    height: 35vh;
    width: 50%;
    padding: 0 1vw 1.5vw 1vw;
}

.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.DisableContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.TableValueParent {
    display: flex;
    justify-content: center;
}

.TableValue {
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.DropDnContainer {
    width: 10vw;
}

.EdgeInfoModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SelectedFile {
    width: 98%;
    height: 3.542vw;
    border: 0.052vw solid #dee4eb;
    border-radius: 0.417vw;
    background: #ffffff;
    margin-top: 0.26vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SelectedFile p {
    color: var(--gray-700) !important;
    font-weight: 600 !important;
    font-size: 0.833vw !important;
    position: relative;
    left: 0.885vw;
}

.CloseIcon {
    position: relative;
    right: 1vw;
    top: 0.156vw;
    cursor: 'pointer';
}

.UploadSection {
    display: flex;
    gap: 5vw;
    overflow: hidden;
}

.HeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.UploadedFiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    height: 20vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.DocumentContainer {
    width: 98vw;
    height: 98vh;
    overflow: auto;
}

.Uploading {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.Menu {
    border-radius: 0.75rem;
}

.SnoozeHeader {
    font-size: 17px;
    font-weight: 600;
}

.MenuOptions {
    display: flex;
    align-items: center;
    gap: 0.5vw;
}

.MenuItem {
    padding-left: 0 1vw;
}

.ScheduleTestInputContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.ControlTestInputContainer {
    display: flex;
    gap: 1vw;
    align-items: end;
}

.PageContainer {
    display: flex;
    gap: 0.5vw;
    height: 4vh;
    border: 1px solid #ccced1;
    border-top: none;
    align-items: center;
    padding-left: 1vw;
    padding-right: 1vw;
    background-color: var(--gray-100);
    align-items: center;
}

.PageInput:focus {
    outline: none;
}

.PageInput {
    border: none;
    background-color: transparent;
    height: 2.7vh;
    padding-left: 0.5vw;
    padding-right: 0.3vw;
}

.ActivePage {
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid var(--color-primary);
    border-left: 1px solid #ccced1;
    border-right: 1px solid #ccced1;
    border-top: 1px solid white;
    height: 3.7vh;
    margin-top: -1px;
}

.RowFlexBtn {
    width: 16.2vw !important;
}

.AddModal {
    width: 50vw;
    height: 90vh;
    overflow: auto;
}

.AddModalFullScreen {
    width: 98vw;
    height: 95vh;
    overflow: auto;
}

.addInputActionWrapper {
    display: flex;
    justify-content: end;
    margin-top: 1vh;
    gap: 0.5vw;
}

.HistoryLink {
    text-align: right;
    font-size: 0.7vw;
    letter-spacing: 0px;
    color: #818e94;
    text-decoration: underline;
    cursor: pointer;
}

.HistoryContainer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 1vw;
}

.EdgeInfoIconContainer {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
}

.EdgeIconContainer {
    display: flex;
    gap: 1vw;
    justify-content: center;
    align-items: center;
}

.ShipmentInfoContainer {
    min-width: 40vw;
    overflow: auto;
    max-height: 70vh;
    min-height: 30vh;
}

.UploadedEdgeFiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.DataMonitoringContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
