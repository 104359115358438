.ThreeStateContainer input[type='radio'] {
    display: none;
}

.ThreeStateContainer label {
    color: white;
    z-index: 1;
    cursor: pointer;
    flex: 1;
}

.BlackFont {
    color: white !important;
}

.Labels {
    display: flex;
    width: 100%;
    margin: 0;
    font-weight: 500;
    font-size: 0.7rem;
    text-align: center;
}

.MainContainer {
    width: 13.5rem;
    height: 1.89rem;
    border-radius: 10vw;
    background-color: #ccc;
    position: relative;
    display: flex;
    align-items: center;
}

.Switch {
    height: 1.5rem;
    width: var(--slider-width);
    background-color: var(--color-primary);
    border-radius: 10vw;
    position: absolute;
    left: 3px;
    top: 3px;
    transition: ease-in;
}

/*---------------- LEFT ------------------- */
.Switch.left-to-center {
    animation-name: leftToCenter;
    animation-duration: 0.5s;
}

.Switch.left-to-right {
    animation-name: leftToRight;
    animation-duration: 0.5s;
}

.Switch.left {
    left: 3px;
}

@keyframes leftToCenter {
    from {
        left: 3px;
    }

    to {
        right: 85px;
    }
}

@keyframes leftToRight {
    from {
        left: 3px;
    }

    to {
        left: var(--right-side-key);
    }
}

/* -------------------- center ------------------ */
.Switch.center-to-left {
    animation-name: centerToLeft;
    animation-duration: 0.5s;
}

.Switch.center-to-right {
    animation-name: centerToRight;
    animation-duration: 0.5s;
}

.Switch.center {
    left: 36%;
}

@keyframes centerToLeft {
    from {
        left: 85px;
    }

    to {
        left: 3px;
    }
}

@keyframes centerToRight {
    from {
        left: 85px;
    }

    to {
        right: 1px;
    }
}

/* ------------------- RIGHT ------------------- */
.Switch.right-to-left {
    animation-name: rightToLeft;
    animation-duration: 0.5s;
}

.Switch.right-to-center {
    animation-name: rightToCenter;
    animation-duration: 0.5s;
}

.Switch.right {
    left: var(--right-side-left);
}

@keyframes rightToLeft {
    from {
        left: var(--right-side-key);
    }

    to {
        left: 3px;
    }
}

@keyframes rightToCenter {
    from {
        left: var(--right-side-key);
    }

    to {
        right: 85px;
    }
}
