.TableContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.Menu {
    border-radius: 0.75rem;
}

.SnoozeHeader {
    font-size: 17px;
    font-weight: 600;
}

.ActionContainer {
    display: flex;
    justify-content: center;
}
.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}
