.CapacityContent {
    height: 38vw;
    width: 32vw;
}

.Content {
    margin: 0 1vw;
}

.CapacityContent table {
    border-collapse: collapse;
}

.CapacityContent tr,
.CapacityContent td,
.CapacityContent th {
    border-bottom: 1px solid rgb(129, 142, 148, 0.1);
    padding: 1vw;
}

.CapacityContent th {
    font-size: 0.72vw;
    font-weight: 500;
    color: #818e94;
}

.CapacityContent td {
    font-size: 0.72vw;
    color: #818e94;
    text-align: center;
}

.Title {
    color: #000000;
    font-weight: bold;
    font-size: 0.83vw;
}

.Container {
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
}

.ContainerStart {
    display: flex;
    justify-content: space-around;
    margin-top: 1vw;
    align-items: start;
}

.ButtonContainer {
    width: 6vw;
    margin-left: auto;
}

.ButtonContainer2 {
    display: flex;
    justify-content: end;
}

.ButtonContainer2>div {
    width: auto;
}

.ReverseBidBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.FieldControl2 {
    width: 100%;
}

.FieldControl2 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.BidInfoModal {
    max-height: 75vh;
    width: auto;
    overflow: auto;
}
