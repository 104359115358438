.BottomContainer {
    height: 28vw;
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 0.78vw !important;
}

.ComponentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 1vw !important;
}

.HeaderLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
