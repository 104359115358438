.NavBar {
    background-color: #fff;
    padding: 0.5vw 1.35vw;
    display: flex;
    justify-content: space-between;
}

.NavBarEnd {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.DashBoard {
    padding: 1.35vw;
}

.ProfilePreview {
    min-height: 50px;
    height: 5.5vh;
    background-color: var(--gray-50);
    border: 1px solid #5f6368;
    border-radius: 8px;
    padding: 0 1.5vh;
    display: flex;
    grid-column-gap: 0.8vw;
    cursor: pointer;
    align-items: center;
    font-weight: 650;
    color: var(--color-primary);
}

.CompanyLogoWrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
}

.CompanyLogo {
    height: 30px;
}

.ProfileImageWrapper {
    display: flex;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #5f6368;
    overflow: hidden;
}

.ProfileImage {
    height: 30px;
    width: 30px;
}

.NotificationIconwrapper {
    display: flex;
    align-items: center;
    position: relative;
    grid-column-gap: 1vw;
}

.NotificationDot {
    background: #ff6767;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    position: absolute;
    top: -5px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
}

.Notification {
    border-radius: 0.75rem;
}

.Notification .Time {
    color: var(--gray-500);
    font-size: 0.625vw;
    margin-top: 0.301vw;
    margin-left: auto;
    font-weight: 400;
}

.NotificationTitle {
    color: var(--gray-900);
    font-size: 16px;
    font-weight: 600;
}

.Notification .SubTitle {
    font-size: 12px;
    color: var(--gray-700);
    font-weight: 500;
}

.NotificationImageWrapper {
    display: flex;
    align-items: center;
}

.TimeAndSource {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MenuItems {
    padding: 0.5vh 1vw;
    width: max-content;
    grid-column-gap: 0.7vw;
    display: flex;
    align-items: center;
}

.Title {
    font-size: 1vw;
    font-weight: 600;
}

.NavBarStart {
    display: flex;
    align-items: center;
}

.RoleName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.UserRole {
    font-size: 13px;
    font-weight: 500;
}

.UserName {
    font-size: 17px;
    font-weight: 600;
}

.ProfileMenu {
    border-radius: 0.75rem;
}

.ProfileMenuItems {
    font-size: 14px;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.CurUserImageWrapper {
    height: 3vw;
    width: 3vw;
    border-radius: 50%;
    overflow: hidden;
    margin: 5px 0;
}

.CurUserImage {
    width: 100%;
}

.CurUImageWrapper {
    height: 2vw;
    width: 3vw;
    display: flex;
    justify-content: center;
    margin: 3px 0;
}

.CurUImageCont {
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    overflow: hidden;
}

.UserEmail {
    font-size: 0.75vw;
}

.UserCustomer {
    font-weight: 400;
    font-size: 0.9vw;
}

.MenuItemClassNameSW2 {
    font-size: 0.8vw;
    display: flex;
    align-items: start;
    grid-column-gap: 0.7vw;
    padding: 0.8vh 1vw;
}

.MenuItemClassNameSW {
    font-size: 0.8vw;
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
    padding: 0.8vh 1vw;
}

.CurrentAccount {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 0.581vw;
    width: fit-content;
    padding: 5px;
    margin: 10px 0;
}

.ClockWrapper {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.TimeWrapper {
    display: flex;
    gap: 8px;
}

.ClockBlocks {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ClockDescription {
    color: #8d8d8d;
    font-size: 11px;
    font-weight: normal;
}
