.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.DataMonitoringContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MenuItem {
    padding-left: 1.55vw;
}

.MenuOptions {
    display: flex;
    align-items: center;
    gap: 0.5vw;
}

.Menu {
    border-radius: 0.75rem;
}

.SnoozeHeader {
    font-size: 17px;
    font-weight: 600;
}

.SnoozeMenuItem {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.375rem 1.5rem;
}

.SnoozeMenuItem:hover {
    background-color: #e7e7ea;
}

.TableValueParent {
    display: flex;
    justify-content: center;
}

.TableValue {
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.Card {
    width: 10vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.Card p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.Card span {
    font-weight: 700;
    font-size: 1vw;
    color: var(--color-primary);
}

.CardContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
}

.TableContainer {
    min-width: 48vw;
    height: 70vh;
    overflow: auto;
    margin-top: 2vh;
}
