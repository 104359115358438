.ParentWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    margin-bottom: 1vw;
}

.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.FlexContainer {
    display: flex;
    align-items: center;
}

.SubHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1vw 1vw !important;
}

.HeaderContainer {
    background-color: #ffffff;
    margin-bottom: 1.5vh;
    border-radius: 0.78vw !important;
}

.BottomContainer {
    height: 28vw;
    background: #f4f7fc 0% 0% no-repeat padding-box !important;
    border-radius: 0.78vw !important;
}

.HeaderLabel {
    text-align: left;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.DatePickerLabel {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin: 0 0 0.2vw 0.05vw;
}

.MenuItem {
    padding-left: 1.55vw;
}

.MenuOptions {
    font-size: 14px;
}

.Menu {
    border-radius: 0.75rem;
}

.MenuOptions a {
    color: black;
    text-decoration: none;
}
