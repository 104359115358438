.Overview {
    flex-grow: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding: 1.56vw 0.9vw;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderRight {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
    margin-right: 1.4vw;
}

.OverviewBorder {
    background: #c1c1c1 0% 0% no-repeat padding-box;
    border: 1px solid #c1c1c1;
    opacity: 0.5;
    margin-top: 1vw;
}

.OverviewContent {
    margin-top: 1vw;
    height: 33vw;
    overflow-y: auto;
    padding-right: 1vw;
}

.FieldControl {
    width: 9.077vw;
}

.FieldControl input {
    margin-top: 0;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    grid-column-gap: 1vw;
}

.AddKey {
    width: 29.08vw;
    height: 11.22vw;
}

.ButtonContainer {
    margin-top: 1vw;
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ChartContainer {
    height: 27vw;
}

.Actions {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
    row-gap: 2vw;
    justify-content: flex-end;
    margin-bottom: 2vh;
}

.ActionsCharger {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
    row-gap: 2vw;
    justify-content: space-between;
    margin-bottom: 2vh;
    margin-top: -2vh;
}

.ActionsRight {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
    row-gap: 2vw;
    justify-content: space-between;
    margin-top: 2vh;
}

.ActivityButton img {
    cursor: pointer;
    /* width: 4.7vw; */
    height: 2vw;
    /* font-weight: 600;
  font-size: .85vw; */
}

.FilterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading {
    font-size: 1.2vw;
    font-weight: bold;
}

.KeyValue {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    column-gap: 0.2vw;
    text-align: left;
}

.IconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5vw;
}

.IconWrapper img {
    width: 1vw;
    height: 1vw;
    cursor: pointer;
}
