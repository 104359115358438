.Login {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 60vw 40vw;
    position: relative;
}

.Login > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Login > div:nth-child(2) {
    background-color: var(--color-primary);
}

.LoginPic {
    width: 42.59vw;
}

.TitleContainer {
    text-align: center;
}

.Title {
    font-weight: bold;
    font-size: 2.6vw;
    letter-spacing: 0px;
    color: var(--color-primary);
    text-transform: capitalize;
}

.Subtitle {
    margin: 0.5vw auto;
    width: 53vw;
    font-size: 1.2vw;
    font-weight: normal;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
}

.LoginContainer {
    width: 37vw;
    height: 39vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: -20px -20px 30px #00000024;
    border-radius: 0.78vw;
    position: absolute;
    right: 5.4vw;
    bottom: 2vw;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-row-gap: 1vw;
}

.LoginForm {
    height: 23.4vw;
    width: 18.75vw;
}

.FormTitle {
    letter-spacing: -0.1px;
    color: #000000;
    font-size: 1.25vw;
}

.FormSubtitle {
    letter-spacing: 0px;
    color: #818e94;
    font-size: 0.72vw;
    margin-top: 0.72vw;
    margin-bottom: 2.23vw;
}

.Form {
    display: grid;
    grid-row-gap: 1vw;
}

.Form input:focus {
    border: 2px solid #1a73e8;
}

.Form input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.5vw;
    height: 2.2vw;
    border: 1px solid #cfd4d6;
    outline: none;
    width: 100%;
    padding: 0.3vw;
    font-size: 0.72vw;
}

.Form input::-webkit-input-placeholder {
    font-family: 'Poppins';
}

.Form input:-ms-input-placeholder {
    font-family: 'Poppins';
}

.Form input:-moz-placeholder {
    font-family: 'Poppins';
}

.Form input::-moz-placeholder {
    font-family: 'Poppins';
}

.FooterText {
    font-size: 0.72vw;
    color: #64686d;
    text-transform: capitalize;
    margin-top: 2.26vw;
}

.ForgetPassword {
    cursor: pointer;
    text-align: end;
    font-size: 0.72vw;
    letter-spacing: -0.06px;
    color: var(--color-primary);
}

.BlueText {
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary);
}

.forgotPassword {
    width: 25vw;
    padding: 1vw;
}

.TimerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    font-size: 0.8vw;
}

.TimerText {
    margin: 0;
}

.ResendText {
    text-decoration: underline;
    cursor: pointer;
}

.InputLabel {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FormForgotAndremember {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SavedUserWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 0.72vw;
}

.SavedUserPreview {
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 50%;
    margin-left: -0.7vw;
    overflow: hidden;
}

.SavedUserPreview:hover {
    z-index: 1;
}

.SavedUserImage {
    width: 2.4vw !important;
    height: 2.4vw !important;
    box-shadow: 0 0 0 0.25rem #fff;
    cursor: pointer;
}
