.Bids {
    background: #fff;
    padding: 0.3vw 1.5vw;
    border-radius: 0.78vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
    text-align: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
    margin-top: 1vw;
}

.Header>div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    margin-top: 1vw;
    align-items: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.5vw;
}

.ActionContainer img {
    width: 1.2vw;
    height: 1vw;
}

.ActionContainer {
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}

.Action {
    display: flex;
    grid-column-gap: 0.5vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.Badges {
    display: flex;
    align-items: center;
    margin-bottom: 0.51vw;
    grid-column-gap: 1vw;
}

.ButtonsContainer {
    display: flex;
    align-items: end;
    grid-column-gap: 1vw;
}

.ButtonContainer {
    margin-top: 1vw;
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer>div {
    width: 7.3vw;
}

.copyToClipBoardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-primary);
    cursor: pointer;
    border-radius: 8px;
    margin-top: 1rem;
}

.copyToClipBoardKey {
    border-right: 2px solid var(--color-primary);
    margin-right: 5px;
    padding: 5px;
}

.Paid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2vw;
}

.DDW {
    min-width: 13vw;
}

.DropdrownWidth {
    width: 9vw;
}

.FieldControl {
    width: 9vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}
