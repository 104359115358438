.Text {
    font-size: 0.729vw;
}

.Heading {
    font-weight: 600;
}

.Header {
    display: flex;
    justify-content: space-between;
    padding-left: 0.156vw;
    padding-right: 0.156vw;
    margin-top: 1vw;
}

.ErrorDropdownContainer {
    display: flex;
    align-items: center;
    gap: 1vw;
}

.ButtonRight {
    width: 10.39vw;
    margin-top: 1vw;
    margin-left: auto;
}

.ResponseContainer {
    display: flex;
    justify-content: center;
    gap: 0.2vw;
}
