.Details {
    background: #fff;
    padding: 0.3vw 1.5vw;
    border-radius: 0.78vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
    gap: 0.3vw;
    max-height: 2vw;
    align-items: center;
    /* margin-top: 2vw; */
}

.TableCountWrap {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header > div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    align-items: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DropdownContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 0.5vw;
    align-content: center;
}

.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
    margin-top: 5vw;
    align-items: center;
}

.ToggleContainer > div {
    margin-top: 0;
}

.ActionContainer {
    display: flex;
    justify-content: center;
    grid-column-gap: 1vw;
}

.ActionContainer img {
    height: 1.2vw;
    width: 1.2vw;
}

.ActionContainer > div:first-child {
    display: flex;
    align-items: center;
}

.InputContainer input {
    margin-top: 0;
}

.Inputs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-row-gap: 1vw;
    width: auto;
    align-items: flex-end;
}

.TableActions {
    display: flex;
    grid-column-gap: 0.3vw;

    position: absolute;
    right: 0;
    bottom: -2.5vw;
}

.TableActions img {
    height: 2vw;
    cursor: pointer;
}

.Bids {
    background: #fff;
    padding: 0.3vw 1.5vw;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header > div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1vw;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 2vw;
}

.ActionContainer img {
    width: 1.2vw;
    height: 1vw;
}

.ActionContainer {
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}

.Action {
    display: flex;
    grid-column-gap: 0.5vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.Badges {
    display: flex;
    align-items: center;
    margin-bottom: 0.51vw;
    grid-column-gap: 1vw;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.LinkTitle {
    color: #818e94;
    font-size: 0.729vw;
    padding-left: 1.5vw;
    padding-top: 0.61vw;
    text-decoration: underline;
    cursor: pointer;
}

.OverRevContainer {
    display: flex;
    justify-content: center;
    gap: 2vw;
}

.Center {
    display: flex;
    justify-content: center;
}

.OverAllParent {
    margin-bottom: 5vh;
    margin-top: 2vh;
}

.OverAllWrapper {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
}

.RevenueCard {
    width: 10vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.RevenueCard p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.RevenueCard span {
    font-weight: 700;
    font-size: 1vw;
    color: var(--color-primary);
}

.RevenueCardTotal {
    font-weight: 500;
    font-size: medium;
    margin: 0;
    background-color: var(--color-primary);
    color: #fff;
    width: 10vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5vw;
    text-align: center;
    border-radius: 0.58vw;
}

.RevenueCardTotal p {
    font-weight: 500;
    font-size: 0.8vw;
    margin: 0;
}

.RevenueCardTotal span {
    font-weight: 700;
    font-size: 1vw;
}

.DropDnContainer {
    width: 9.077vw;
}

.DropDnContainer label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}
