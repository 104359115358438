.Modal {
    width: 30vw;
}

.ModalHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.AlertIcon {
    display: flex;
}

.AlertIcon img {
    width: 2.03vw;
}

.ModalContent {
    margin-top: 0.7vw;
    width: 18.5vw;
    font-size: 0.72vw;
    letter-spacing: 0px;
    color: #818e94;
    opacity: 1;
}

.Footer div {
    padding: 0 2.65vw;
}

.Footer {
    display: flex;
    justify-content: flex-end;
    width: 7vw;
    margin-left: auto;
}
