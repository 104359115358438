/* root node */
.NodeRoot > circle {
    fill: var(--color-primary);
}

/* node with children */
.NodeBranch > circle {
    fill: var(--color-primary);
}

.Text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5vw;
    height: 100%;
    padding-left: 1vw;
    padding-right: 1vw;
    justify-content: space-between;
}
