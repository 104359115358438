.AddIcon {
    display: flex;
    justify-content: flex-end;
    gap: 0.5vw;
    margin-top: 1vh;
}

.Container {
    width: 30vw;
}

.CapacityContainer {
    max-height: 70vh;
    width: auto;
    overflow: auto;
}

.DMinusContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9vw, 1fr));
    gap: 0.5vw;
    max-width: 60vw;
}
