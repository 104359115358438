.InstanceName {
    font-size: 0.8vw;
    font-weight: 500;
}

.MainModalContainer {
    width: 60vw;
    max-height: 85vh;
    overflow: auto;
}

.DateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.InfoModalContainer {
    height: auto;
    max-height: 75vh !important;
    width: auto;
    overflow: auto;
}

.InfoModalHeading {
    margin-left: 1.5vw;
    margin-top: 1.5vh;
}

.PostBidModalHeading {
    font-size: 0.8vw;
    font-weight: 500;
}


.DMinusContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9vw, 1fr));
    gap: 0.5vw;
    max-width: 60vw;
}

