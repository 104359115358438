.Details {
    background: #fff;
    padding: 0.3vw 1.5vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header>div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    margin-top: 1vw;
    align-items: flex-end;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DropdownContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 2vw;
    margin-top: 5vh;
}

/* css for Add vehicles modal */
.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
    margin-top: 5vw;
    align-items: center;
}

.ToggleContainer>div {
    margin-top: 0;
}

.ActionContainer {
    display: flex;
    justify-content: center;
    grid-column-gap: 1vw;
}

.ActionContainer img {
    height: 1.2vw;
    width: 1.2vw;
}

.ActionContainer>div:first-child {
    display: flex;
    align-items: center;
}

.InputContainer input {
    margin-top: 0;
}

.Inputs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-row-gap: 1vw;
    width: auto;
    align-items: flex-end;
}

.TableActions {
    display: flex;
    grid-column-gap: 0.3vw;

    position: absolute;
    right: 0;
    bottom: -2.5vw;
}

.TableActions img {
    height: 2vw;
    cursor: pointer;
}

.Bids {
    background: #fff;
    padding: 0.3vw 1.5vw;
}

.TableCount {
    font-size: 0.72vw;
    background: #f4f7fc 0% 0% no-repeat padding-box;
    border-radius: 0.26vw;
    padding: 0.5vw;
    color: #9da7ab;
    display: flex;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.Header>div:first-child {
    display: flex;
    align-items: center;
    grid-column-gap: 2vw;
}

.BadgeContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.7vw;
}

.SubmitButton {
    margin-bottom: 0.1vw;
}

.BidCapacity {
    width: 32vw;
}

.DatePickers {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.51vw;
    margin-top: 1vw;
    align-items: center;
}

.FilterContainer {
    display: flex;
    justify-content: space-between;
}

.DateLabels {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin-bottom: 0.5vw;
}

.DateSubmit {
    width: 4vw;
    margin-bottom: 0.2vw;
}

.ActionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 2vw;
}

.ActionContainer img {
    width: 1.2vw;
    height: 1vw;
}

.ActionContainer {
    font-size: 0.72vw;
    color: #818e94;
    text-decoration: underline;
}

.Action {
    display: flex;
    grid-column-gap: 0.5vw;
}

.SubHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.Badges {
    display: flex;
    align-items: center;
    margin-bottom: 0.51vw;
    grid-column-gap: 1vw;
}

.ButtonsContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 1vw;
}

.LinkTitle {
    color: #818e94;
    font-size: 0.729vw;
    padding-left: 1.5vw;
    padding-top: 0.61vw;
    text-decoration: underline;
    cursor: pointer;
}

.DropDnContainer {
    width: 9.077vw;
}

.DropDnContainer label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}
