.Text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5vh;
}

.ButtonContainer {
    margin-top: 1vw;
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.FieldControl {
    width: 100%;
}
