.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ProfileIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfileIcon {
    height: 1.5vw;
}

.ActionContainer {
    display: flex;
    column-gap: 0.7vw;
    justify-content: center;
    align-items: center;
}

.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.InfoModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TableValueParent {
    display: flex;
    justify-content: center;
}

.TableValue {
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.migrationContainer {
    display: flex;
    justify-content: space-evenly;
    grid-column-gap: 0.3vw;
    padding: 1vw;
}

.dropDown {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    width: 25vw;
}

.SummaryDropDownContainer {
    display: flex;
    gap: 1vw;
    align-items: center;
}

.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}

.StatusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.TableContainer {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
}

.BoldFont {
    font-weight: bold;
}

.GreenColor {
    color: green;
}

.RedColor {
    color: red;
}

.AmountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.OverviewFilterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
