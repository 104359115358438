.ModalWrapper {
    max-height: 80vh;
    overflow: auto;
}

.label {
    font-size: 12px;
}

.cronActionConatiner {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
}
