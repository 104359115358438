.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FormContainer {
    /* width: 100%; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    padding: 1.56vw 0.9vw;
    height: 30vw;
    overflow-y: scroll;
}

.FieldControl2 {
    width: 100%;
}

.FieldControl3 {
    width: 15vw;
}

.FieldControl {
    width: 34.15vw;
}

.FieldControl input,
.FieldControl2 input,
.FieldControl3 input {
    margin-top: 0;
}

.FieldControl label,
.FieldControl2 label,
.FieldControl3 label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.InputContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.FormContent {
    padding: 0 2vw;
    margin-top: 1vw;
}

.RadioContainer {
    margin-top: 1vw;
    display: flex;
    grid-column-gap: 1vw;
}

.RadioContainer label {
    display: flex;
    grid-column-gap: 1vw;
}

.RadioContainer span {
    text-align: right;
    font-size: 0.72vw;
    color: #000000;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer > div {
    width: 7.3vw;
}

.SmallInputs {
    display: flex;
    grid-column-gap: 1vw;
    margin-left: 1vw;
    margin-top: -2vw;
}

.TableActions {
    display: flex;
    grid-column-gap: 0.3vw;

    position: absolute;
    right: 0;
    bottom: -2.5vw;
}

.TableActions img {
    height: 2vw;
    cursor: pointer;
}

.ButtonContainer button {
    margin-top: 3vw;
}

.Title {
    font-size: 1vw;
    margin-top: 1vw;
    font-weight: 500;
}

.InputTitle {
    margin-top: 2vw;
    margin-bottom: -0.5vw;
    font-size: 0.725vw;
    letter-spacing: 0px;
    color: #818e94;
}

.FlexBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.RMLogoWrapper {
    display: flex;
    align-items: center;
}

.LogoWrapper {
    margin: 1vw 0 0 1vw;
}

.addInputActionWrapper {
    display: flex;
    justify-content: end;
    margin-top: 1vh;
    margin-right: 1vw;
}

.addInputActionbutton {
    height: 1.8rem;
    margin-left: 0.3rem;
}

.ProfileMenu {
    border-radius: 0.75rem;
    position: relative !important;
}

.ProfileMenuItems {
    font-size: 14px;
}

.MenuItemHover {
    background-color: transparent;
}
