.Container {
    position: relative;
    display: flex;
    align-items: center;
}

.CalendarIcon {
    position: relative;
    right: 0.417vw;
    top: 0.104vw;
    color: var(--gray-900);
}

.DateContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f4f7fa;
    border: 1px solid #dee4eb;
    border-radius: 0.417vw;
    padding-left: 0.6vw;
    width: 13vw;
    height: 2vw;
}

.Date {
    font-weight: 600;
    font-size: 0.833vw;
    color: var(--gray-900);
}

.CalendarPopUp select:hover {
    background-color: transparent !important;
}

.CalendarPopUp select option {
    background-color: transparent;
    text-align: center;
}

.CalendarPopUp select {
    border-radius: 0.417vw;
}

.ArrowButton {
    background: var(--color-primary);
    border-radius: 0.3vw;
    width: 2vw;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2vw;
    cursor: pointer;
}

.DatePickerContainer {
    display: flex;
    align-items: center;
    grid-column-gap: 0.51vw;
}
