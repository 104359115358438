.DeviceDashboard {
    display: flex;
    grid-column-gap: 1vw;
}

.FieldControl {
    width: 31.25vw;
}

.FieldControl input {
    margin-top: 0 !important;
}

.FieldControl label {
    text-align: right;
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
}

.ButtonContainer {
    justify-content: flex-end;
    display: flex;
    grid-column-gap: 1vw;
}

.ButtonContainer>div {
    width: 7.3vw;
}

.ActionIcon {
    height: 0.9vw;
    width: 0.7vw;
}

.wholeFilterWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.totalCount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
    display: flex;
    justify-content: space-between;
}

.FilterContainer {
    display: flex;
    align-items: center;
    gap: 1vw;
}

.DMinusContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9vw, 1fr));
    gap: 0.5vw;
    max-width: 60vw;
}

.InfoModalContainer {
    height: auto;
    max-height: 75vh !important;
    width: auto;
    overflow: auto;
}

.InfoModalHeading {
    margin-left: 1.5vw;
    margin-top: 1.5vh;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 1vw;
    margin-top: 1vw;
}

.ButtonContainer button {
    width: 5vw;
}

.CardsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.Card {
    border-radius: 0.78vw;
    background: #f4f4f6;
    padding: 0.83vw;
    cursor: pointer;
    border: #f4f4f6 solid 1px;
}

.Title {
    font-size: 0.83vw;
    letter-spacing: 0px;
    color: var(--color-primary);
    text-transform: capitalize;
    opacity: 1;
    font-weight: bold;
}

.SubTitle {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #818e94;
    text-transform: capitalize;
    opacity: 1;
    font-weight: 500;
}

.TitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0vw;
}

.Content {
    font-size: 0.73vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 500;
    line-height: 2;
    margin-left: 1vw;
    overflow-y: auto;
    list-style-position: inside;
}
