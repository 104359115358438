.Filter {
    background: white;
    padding: 0.5vw 1vw 1vw;
    margin-bottom: 1vh;
    border-radius: 0.78vw;
}

.FilterContainer {
    display: flex;
    gap: 1vw;
}

.DatePickerLabel {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin: 0 0 0.2vw 0.05vw;
    text-align: left;
}

.SelecterContainer {
    display: flex;
    flex-direction: column;
}

.LeftContainer {
    display: flex;
    gap: 0.5vw;
    align-items: center;
}

.FileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.UploadFileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
}

.DownloadButtonControl {
    gap: 4vw;
    padding: 2vw;
}

.ActionButton {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
}
