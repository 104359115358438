.StatusContainer {
    display: flex;
    justify-content: center;
    gap: 0.5vw;
}

.Status {
    padding: 3px 8px;
    text-align: center;
    border-radius: 8px;
    color: white;
}

.AccessBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: red;
}

.AccessNotBlocked {
    height: 0.7vh;
    width: 0.7vw;
    background-color: green;
}

.StatusContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
