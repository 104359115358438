.ParentWrapper {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 0.78vw;
    margin-bottom: 1vw;
    padding: 1vw;
    display: flex;
    justify-content: space-between;
}

.Filter {
    display: flex;
    flex-direction: column;
}

.Filter > label {
    font-size: 0.625vw;
    letter-spacing: 0px;
    color: #818e94;
    margin: 0 0 0.2vw 0.05vw;
}

.Left,
.Right {
    display: flex;
    gap: 0.5vw;
    align-items: end;
}
